import "react-toastify/dist/ReactToastify.css";
import './App.css';
import { ToastContainer, toast } from "react-toastify";
import { useEffect, useLayoutEffect, useState } from "react";

// import CINCO from './CINCO.webp'
// import FERRARI from './assets/FERRARI.jpeg'
// import MOON from './assets/MOON.jpeg'
// import TOOLS from './assets/TOOLS.jpeg'
// import FARM from './assets/FARM.jpeg'
// import GOKU from './assets/GOKU.jpeg'
// import DRIP from './assets/DRIP.jpeg'
// import GLASSES from './assets/GLASSES.jpeg'
// import GUITAR from './assets/GUITAR.jpeg'
import iconTg from './iconTg.webp'
import iconX from './iconX.webp'
import iconDex from './iconDex.webp'

import deggo from './assets/deggo.png'
import afro from './assets/afro.png'
import blonde from './assets/blonde.png'
import cool from './assets/cool.png'
import deggo_911 from './assets/deggo_911.png'
import deggo_clipart from './assets/deggo_clipart.png'
import killer from './assets/killer.png'
import many_deggo from './assets/many_deggo.png'
import smoke from './assets/smoke.png'
import waffle from './assets/waffle.png'

const urlSite = "4GJThNHSLjxVJb5ZCqFNUqNSwuvFHqchtKRyEX1VNxAo\n"


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


function App() {
  const [isMobile, setIsMobile] = useState(false)
  const [width] = useWindowSize();
  const copyUrlSte = () => {
    navigator.clipboard.writeText(urlSite);
    toast("Copied!", {type: "success"})
  }
  useEffect(() => {
    if (width < 850 && !isMobile) {
      setIsMobile(true)
    } else if(width >= 850 && isMobile) {
      setIsMobile(false)
    }
  }, [width, isMobile]);
  //https://twitter.com/deggoonsol
  const telegram = "https://t.co/R0g2vzueFD";
  const twitter = "https://twitter.com/deggoonsol";
  const dex = "https://www.pump.fun/4GJThNHSLjxVJb5ZCqFNUqNSwuvFHqchtKRyEX1VNxAo";

  return (
    <div className={"App"}>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        closeButton={true}
        pauseOnHover={false}
        theme="dark"
      />
      <div className={`${isMobile ? 'social mobile' : 'social'}`}>
        <a href={telegram} target="_blank" rel="noreferrer">
          <img src={iconTg} alt="telegram"/>
        </a>
        <a href={twitter} target="_blank" rel="noreferrer">
          <img src={iconX} alt="x"/>
        </a>
        <a href={dex} target="_blank" rel="noreferrer">
          <img src={iconDex} alt="dex"/>
        </a>
      </div>
      <div className={`${isMobile ? 'cinco mobile' : 'cinco'}`}>
        <h1>$deggo</h1>
        <img src={deggo} alt="deggo"/>
        <h2>69</h2>
        <div className="link">
          <div className="url">{urlSite}</div>
          <button onClick={copyUrlSte}>copy</button>
        </div>
      </div>
      <div className={`${isMobile ? 'memes mobile' : 'memes'}`}>
        <h1>deggo memes</h1>
        <div className="memeBlock">
          <img src={afro} alt="afro"/>
          <img src={blonde} alt="blonde"/>
          <img src={cool} alt="cool"/>
          <img src={deggo_911} alt="deggo_911"/>
        </div>
        <div className="memeBlock">
          <img src={deggo_clipart} alt="deggo_clipart"/>
          <img src={killer} alt="smoke"/>
          <img src={smoke} alt="PURPLE"/>
          <img src={waffle} alt="waffle"/>
        </div>
      </div>
    </div>
  );
}

// import afro from './assets/afro.png'
// import blonde from './assets/blonde.png'
// import cool from './assets/cool.png'
// import deggo_911 from './assets/deggo_911.png'
// import deggo_clipart from './assets/deggo_clipart.png'
// import killer from './assets/killer.png'
// import many_deggo from './assets/many_deggo.png'
// import smoke from './assets/smoke.png'
// import waffle from './assets/waffle.png'

export default App;
